<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Liste des contacts</h1>
			<p class="text-muted">Retrouvez ici tous vos contacts, triez les ou exportez les.</p>
		</div>

		<div class="p-col-12">
			<div class="card">
				<ContactsList></ContactsList>
			</div>
		</div>
	</div>
</template>

<script>
import ContactsList from "../components/app/crm/ContactsList.vue";

export default {
	data() {
		return {
			dut: undefined,
		}
	},
	created(){
	},
	mounted(){
	},
	components: {
		ContactsList
	}
}
</script>

<style scoped>

</style>
