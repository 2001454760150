<template>
	<div class="p-grid">
		<div class="p-col-12">
			<DataTable :value="contacts" :lazy="true" class="p-datatable-gridlines" dataKey="id" :paginator="true" :rows="rows"
					   :totalRecords="totalEntities" :rowHover="false" :loading="loading" @page="onPage($event)" @sort="onSort($event)" responsiveLayout="scroll">
				<template #header>
					<div class="p-d-flex p-jc-between p-ai-center p-flex-wrap">
						<div class="left">
							<div class="selector p-mr-2">
								<ContactStatusSelector v-model="statusFilter" v-on:change="changeStatusFilter"></ContactStatusSelector>
							</div>
						</div>



						<div class="right p-pt-2 p-pt-sm-0">
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText type="text" :value="filterSearchValue" @input="changeQueryStringFilter" placeholder="Chercher par Nom" />
							</span>
						</div>
					</div>
				</template>
				<template #empty>
					<div class="p-fluid p-d-flex p-justify-center p-ai-center">
						<ErrorMessageBc type="no_stats" message="Pas de contacts" detail="commencez à utilisez Freecard dès maintenant !"></ErrorMessageBc>
					</div>
				</template>
				<template #loading>
					<small class="text-muted">Chargement des informations</small>
				</template>
				<Column field="email" header="Email"></Column>
				<Column field="lastname" :sortable="true" header="Nom"></Column>
				<Column field="firstname" header="Prénom"></Column>
				<Column field="company" header="Société"></Column>
				<Column field="dateLastContact" :sortable="true" header="Date derniers contact">
					<template #body="{data}">
						<span class="image-text">{{ tmsToDate(data.dateLastContact) }}</span>
					</template>
				</Column>
				<Column field="status" :sortable="true" header="Statut">
					<template #body="{data}">
						<div v-if="data.status === 1" class="p-message p-component  p-message-info">
							<div class="p-message-wrapper">
								<b>Nouveau</b>
							</div>
						</div>
						<div v-else-if="data.status === 2" class="p-message p-component  p-message-error">
							<div class="p-message-wrapper">
								<b>Non-Qualifié</b>
							</div>
						</div>
						<div v-else-if="data.status === 3" class="p-message p-component  p-message-info">
							<div class="p-message-wrapper">
								<b>Proposition</b>
							</div>
						</div>
						<div v-else-if="data.status === 4" class="p-message p-component  p-message-success">
							<div class="p-message-wrapper">
								<b>Qualifié</b>
							</div>
						</div>
						<div v-else-if="data.status === 5" class="p-message p-component  p-message-warn">
							<div class="p-message-wrapper">
								<b>Renouvellement</b>
							</div>
						</div>
						<div v-else-if="data.status === 6" class="p-message p-component  p-message-warn">
							<div class="p-message-wrapper">
								<b>Négotiation</b>
							</div>
						</div>

					</template>
				</Column>
				<Column field="hot_rate" :sortable="true" header="Maturité du prospect">
					<template #body="{data}">
						<HotBar :editable="false" :value="data.hot_rate"></HotBar>
					</template>
				</Column>
				<Column field="id" header="">
					<template #body="{data}">
						<Button icon="pi pi-eye" @click="viewContact(data)" class="p-button-outlined"></Button>
					</template>
				</Column>
				<template #footer>
					<div class="p-d-flex p-jc-between p-ai-center p-m-0">
						<div class="left">
						</div>
						<div class="right">
							<span class="p-input-icon-left">
								<Button label="Exporter la liste"
										icon="pi pi-download" @click="downloadAll" class="p-button-outlined b-button"></Button>
							</span>
						</div>
					</div>
				</template>
			</DataTable>
		</div>
	</div>
</template>

<script>
	import CrmService from "../../../service/CrmService";
	import HotBar from "./HotBar.vue";
	import ContactStatusSelector from './ContactStatusSelector.vue';

export default {
	data() {
		return {
			dut: undefined,
			contacts: [],
			totalEntities: 0,
			rows: 10,
			loading: false,
			filterSearchValue: '',
			order: undefined,
			orderDirection: false,
			page: 1,
			statusFilter: undefined,
			timeoutPtr: undefined,
		}
	},
	created(){
		this.crmService = new CrmService()
	},
	mounted(){
		this.loadData()
		;
	},
	methods: {
		downloadAll(){
			this.loading = true;
			this.crmService.downloadContact(
					this.order,
					this.orderDirection,
					this.statusFilter,
					this.filterSearchValue,
			)
					.finally(() => {
						this.loading = false;
					})
		},
		registerReload(){
			const vm = this;
			if(this.timeoutPtr !== undefined){
				this.cancelReload();
			}
			this.timeoutPtr = setTimeout(() => {
				vm.loadData();
			}, 400)
		},
		cancelReload(){
			try {
				clearTimeout(this.timeoutPtr);
			} catch (e) {
				console.warn(e);
			}
		},
		viewContact(contact) {
			this.$router.push({ name: 'crm-contacts-profile', params: { guid: contact.guid } })
		},
		changeStatusFilter(event){
			let c = [];
			event.forEach(el => {
				c.push(el.code)
			});
			this.statusFilter = c;
			this.registerReload();
		},
		changeQueryStringFilter(event){
			let value = event.target.value;
			console.log(value);
			this.filterSearchValue = value;
			this.registerReload();
		},
		onPage(event) {
			this.page = event.page + 1;
			this.loadData();
		},
		onSort(event) {
			console.log(event)
			switch(event.sortField){
				case 'lastname':
					this.order = 'lastname';
					break;
				case 'status':
					this.order = 'status';
					break;
				case 'hot_rate':
					this.order = 'hotness';
					break;
				case 'dateLastContact':
					this.order = 'last_contact';
					break;
				default:
					this.order = undefined;
			}

			if(event.sortOrder === 1){
				this.orderDirection = true;
			} else {
				this.orderDirection = false;
			}

			this.loadData();
		},
		tmsToDate(tmxUNIX){
			let date = new Date(tmxUNIX * 1000);
			return date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes();
		},
		loadData(){
			const vm = this;
			this.loading = true;
			this.crmService.getContacts(
					this.rows,
					this.page,
					this.order,
					this.orderDirection,
					this.statusFilter,
					this.filterSearchValue,
			)
					.then(data => {
						if(data.data.error === true){
							throw new Error(data.data.message);
						}

						const p = data.data;
						vm.contacts = p.data;
						vm.totalEntities = p['@meta'].total;

					})
					.catch(err => {
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.loading = false;
					})
		}
	},
	components: {
		HotBar,
		ContactStatusSelector,
	}
}
</script>

<style scoped>

</style>
